import { VFC } from "react";
import { BaseSetting } from "src/entities/base";
import StyledSwitch from "../../common/Switch";

type SelectionType = "countries" | "categories"

type CountryCode = "IDN" | "SGP" | "MYS" | "PHL" | "THA" | "VNM" | "AUS"

interface Props {
  type: SelectionType
  categories: BaseSetting[];
  selectedValue: BaseSetting[];
  onChange: (category: BaseSetting, checked: boolean) => void;
}

const SubCategorySelection: VFC<Props> = (props) => {
  const onValueChange =
    (category: BaseSetting) =>
    (_event: React.ChangeEvent, checked: boolean) => {
      props.onChange(category, checked);
    };

  return (
    <>
      {props.categories.map((cate) => (
        <StyledSwitch
          key={cate.id}
          value={props.selectedValue.some((option) => option.id === cate.id)}
          label={mapCategoryName(props.type, cate.name)}
          onChange={onValueChange(cate)}
        />
      ))}
    </>
  );
};

const mapCategoryName = (type: SelectionType, name: string): string => {
  if (type === "categories") {
    return name
  }

  return normalizeCountryName(name)
}

const normalizeCountryName = (name: string): string => {
  if (name as CountryCode === "SGP") {
    return "Singapore"
  } else if (name as CountryCode === "MYS") {
    return "Malaysia"
  } else if (name as CountryCode === "PHL") {
    return "Philippines"
  } else if (name as CountryCode === "THA") {
    return "Thailand"
  } else if(name as CountryCode === "VNM") {
    return "Vietnam"
  } else if(name as CountryCode === "AUS") {
    return "Australia"
  } else {
    return "Indonesia"
  }
}

export default SubCategorySelection;
